import { sortBy } from 'lodash';
import { useSnapshot } from 'valtio';
import { signalrHooks2 } from '../SDK/Signalr/signalrHooks2';
import { useSortSignalrDataStore, useSortSignalrDataStore2, useSortSignalrDataStore3, } from '../symbolQuote/simple/useSortStore';
/** option: 商品清單, 排序組別:1||2 */
export const useSignalr2QuoteSort = (data, sortGroup) => {
    const sortGroup_ = sortGroup ?? 1;
    const sortStore = () => {
        if (sortGroup_ === 2) {
            return useSortSignalrDataStore2;
        }
        else if (sortGroup_ === 3) {
            return useSortSignalrDataStore3;
        }
        else
            return useSortSignalrDataStore;
    };
    const sortSnap = useSnapshot(sortStore());
    const sortKey = sortSnap.sortKey;
    const orderKey = sortSnap.orderKey;
    const value = signalrHooks2.useQuotes(data);
    const valueData = () => {
        if (sortKey === 'change')
            return sortBy(value, datum => (datum?.close ?? 0) - (datum?.prevRef ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === 'changePrecent')
            return sortBy(value, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01).map(datum => datum?.symbol);
        else if (sortKey === 'none')
            return value.map(datum => datum?.symbol);
        else if (sortKey === 'amount')
            return sortBy(value, datum => (datum?.close ?? 0) * (datum?.volume ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === sortKey)
            return sortBy(value, datum => datum?.[sortKey]).map(datum => datum?.symbol);
    };
    const resultData = orderKey === 'desc' ? valueData()?.reverse() : valueData();
    return resultData;
};
